// eslint-disable-next-line max-classes-per-file
import { Case, UpdateCaseRequest } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'
import { RemoteData } from '@ftr/foundation'

export class FetchCaseAction {
  static readonly type = '[Cases] Fetch Case'

  constructor(
    readonly courtSystemId: Uuid,
    readonly caseId: Uuid,
  ) {}
}

export class SetCaseRemoteAction {
  static readonly type = '[Cases] Set Case Remote'

  constructor(
    readonly caseId: Uuid,
    readonly caseRemote: RemoteData<Case>,
  ) {}
}

export class UpdateCaseAction {
  static readonly type = '[Cases] Update Case'

  constructor(
    readonly caseId: Uuid,
    readonly caseUpdate: UpdateCaseRequest,
  ) {}
}

export class DeleteCaseAction {
  static readonly type = '[Cases] Delete Case'

  constructor(readonly caseId: Uuid) {}
}
