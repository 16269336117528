import { CommonModule } from '@angular/common'
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { generateUuid } from '@ftr/contracts/type/shared'
import { Icon, IconComponent } from '@ftr/foundation'
import { TristateValue } from '../checkbox-tree/checkbox-item'

/**
 * An extremely lightweight wrapper for an input checkbox element.
 *
 * IMPORTANT: Should only be used for high performance settings, eg. in checkbox lists/trees, where hundreds can be
 * on one page.
 *
 * - Is always tri-state
 * - Does not support form validation
 *
 */
@Component({
  selector: 'ftr-basic-checkbox',
  templateUrl: './basic-checkbox.component.html',
  styleUrls: ['./basic-checkbox.component.css'],

  host: { class: 'basic-checkbox input input--checkbox' },
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent],
})
export class BasicCheckboxComponent implements OnInit {
  @Input() id = `ftr-basic-checkbox-${generateUuid()}`
  @Input() name: string
  @Input() icon: Icon | undefined
  @Input() summary: string
  @Input() value: TristateValue = false
  @Input() disabled = false
  @Input() isTitleBold = false
  @Output() valueChange = new EventEmitter<boolean>()

  labelName: string
  descriptionName: string

  getBooleanValue(): boolean {
    return this.value === true
  }

  @HostBinding('class.basic-checkbox--highlight') highlight = false

  ngOnInit(): void {
    this.labelName = `${this.id}Label`
    this.descriptionName = `${this.id}Description`
  }

  onChange(value: boolean): void {
    this.valueChange.emit(value)
  }
}
