import { AriaDescriber } from '@angular/cdk/a11y'
import {
  ConnectedOverlayPositionChange,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollDispatcher,
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { DOCUMENT } from '@angular/common'
import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  effect,
  input,
} from '@angular/core'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  fromEvent,
  interval,
  map,
  of,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
  throttle,
} from 'rxjs'
import { ALIGNMENTS_CLASS_PREFIX, VIEWPORT_MARGIN } from './tooltip-constants'
import { Point, TooltipAlignment, TooltipPosition, getPosition, invertPosition } from './tooltip-position'
import { TooltipComponent } from './tooltip.component'

export const SCROLL_THROTTLE_MS = 20
export const MOUSE_MOVE_THROTTLE_MS = 50
export const IGNORE_MOUSE_OVER_AFTER_TOUCH_START_MS = 100
export const INTERACTIVE_TOOLTIP_HIDE_TIME_MS = 600

export type TooltipDisplay = 'show' | 'hide'

enum TooltipHideBehaviour {
  Immediate = 'Immediate',
  DelayWhenInteractable = 'DelayWhenInteractable',
}

interface TooltipVisibilitySource {
  source$: BehaviorSubject<boolean>
  hideBehaviour: TooltipHideBehaviour
}

/**
 * Allows you to automatically add a tooltip to any element
 *
 * - Handles ARIA attributes for you
 * - Supports templates and plain text
 * - Will always stay on screen, dynamically adjusts position based on edge of screen
 * - Scrolls with the user, with a scroll throttle
 */
@Directive({
  selector: '[ftrTooltip]',
  exportAs: 'ftrTooltip',
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  /**
   * Where the tooltip should be rendered relative to the element
   */
  @Input()
  position: TooltipPosition = 'above'

  /**
   * How the tooltip should be aligned relative to the side specified in position (Where should the arrow be)
   */
  @Input()
  get alignment(): TooltipAlignment {
    return this._alignment
  }
  set alignment(value: TooltipAlignment) {
    if (value !== this._alignment) {
      this._alignment = value
      this.mouseMoveSubscription?.unsubscribe()
      if (this.alignment === 'mouse') {
        this.setupMouseMovementTooltipUpdating()
      }
    }
  }

  /**
   * An optional template for the inside of the tooltip.
   *
   * If provided, message is still used for aria described by, but the rendered contents will be the template
   */
  @Input()
  template?: TemplateRef<any>

  @Input()
  templateContext?: Object

  /**
   * Whether to show the standard tooltip underline on the host component
   */
  readonly showDash = input(true)

  /**
   * How long to wait in millisecond before hiding the tooltip.
   */
  @Input()
  public hideTimeDelay: number = INTERACTIVE_TOOLTIP_HIDE_TIME_MS

  /**
   * The message to be displayed, for most tooltips, you will just need to provide a message.
   */
  @Input()
  get message(): string {
    return this._message
  }
  set message(value: string) {
    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this.message, 'tooltip')
    this._message = value
    this.ariaDescriber.describe(this.elementRef.nativeElement, this.message, 'tooltip')
  }

  /**
   *  By default, the tooltip hides and shows based on the host element mouseover and leave. When this is true it will
   * delay hiding until a timeout has passed and neither the tooltip or the host element are being hovered.
   *  */
  @Input() interactive: boolean = false

  /**
   * This enabled tooltips to stay open when clicked until the user clicks outside of the tooltip or the host element.
   * Useful primarily for showing the tooltips on mobile.
   */
  @Input() clickToStayOpen: boolean

  @Input() forceDisplayState?: TooltipDisplay | undefined

  /**
   * If true, the tooltip will only show if the host element is overflowing.
   * Note: forceDisplayState will override this setting.
   */
  @Input() hideIfNotOverflowing: boolean = false

  @Output() tooltipDisplayChange = new EventEmitter<TooltipDisplay>()

  portal: ComponentPortal<TooltipComponent>

  private tooltipRef: ComponentRef<TooltipComponent>

  private overlayRef: OverlayRef | null

  // Note: Directives cannot inherit DestroySubscribers so doing it manually
  private destroy: ReplaySubject<void> = new ReplaySubject<void>(1)

  private _message = ''
  private _alignment: TooltipAlignment = 'center'

  // Latest mouse coordinates - used when alignment is set to 'mouse'
  private relativeMouseCoords?: Point
  // Use a subject to allow throttling of mouse movement based tooltip updating
  private mouseMoved$ = new Subject<void>()
  private mouseMoveSubscription: Subscription | undefined

  private lastTouchStart: Date | undefined
  private mouseOver$ = new BehaviorSubject(false)
  private tooltipMouseOver$ = new BehaviorSubject(false)

  private lastClickedOnHostOrTooltip$ = new BehaviorSubject(false)

  private forceDisplayState$ = new BehaviorSubject<TooltipDisplay | undefined>(undefined)

  private hideIfNotOverflowing$ = new BehaviorSubject(false)

  private resizeObserver: ResizeObserver

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private scrollDispatcher: ScrollDispatcher,
    private ariaDescriber: AriaDescriber,
    @Inject(DOCUMENT) private document: Document,
  ) {
    effect(() => {
      // see main.css for tooltip-dash
      if (this.showDash()) {
        this.elementRef.nativeElement.classList.add('tooltip-dash')
      } else {
        this.elementRef.nativeElement.classList.remove('tooltip-dash')
      }
    })

    this.resizeObserver = new ResizeObserver(() => this.onResize())
    this.resizeObserver.observe(document.body)
  }

  @HostListener('mouseover')
  private mouseOver(): void {
    if (this.isMouseOverNotPartOfTouchEvent()) {
      this.mouseOver$.next(true)
    }
  }

  private onResize(): void {
    const hostEl = this.elementRef.nativeElement
    const hideIfHostElNotOverflowing = this.hideIfNotOverflowing
      ? hostEl.scrollHeight <= hostEl.clientHeight && hostEl.scrollWidth <= hostEl.clientWidth
      : false
    this.hideIfNotOverflowing$.next(hideIfHostElNotOverflowing)
  }

  @HostListener('mouseleave')
  private mouseLeave(): void {
    this.mouseOver$.next(false)
  }

  @HostListener('touchstart')
  private touchStart(): void {
    this.lastTouchStart = new Date()
  }

  @HostListener('click')
  private click(): void {
    if (this.clickToStayOpen) {
      this.lastClickedOnHostOrTooltip$.next(true)
    }
  }

  setupCloseOnClickOutside(): void {
    const documentClick = fromEvent<MouseEvent>(document, 'click')
    this.tooltipDisplayChange
      .pipe(
        filter(display => display === 'show'),
        switchMap(() => documentClick),
        // Ensure we are not clicking inside the tooltip or the host
        filter(event => {
          if (event.target instanceof Element) {
            const tooltipEl = this.tooltipRef.instance.tooltip.nativeElement
            const hostEl = this.elementRef.nativeElement

            return !hostEl.contains(event.target) && !tooltipEl.contains(event.target)
          }

          return true
        }),
        takeUntil(this.destroy),
      )
      .subscribe(() => {
        this.lastClickedOnHostOrTooltip$.next(false)
      })
  }

  setPortal(): void {
    this.portal = this.portal || new ComponentPortal(TooltipComponent)
  }

  show(): void {
    this.createOverlay()
    this.setPortal()
    this.overlayRef?.detach()
    this.tooltipRef = this.overlayRef!.attach(this.portal)

    this.tooltipRef.instance.onMouseOver
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.tooltipMouseOver$.next(true))
    this.tooltipRef.instance.onMouseLeave
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.tooltipMouseOver$.next(false))
    this.updateTooltipComponent()
    this.tooltipDisplayChange.emit('show')
  }

  hide(): void {
    this.overlayRef?.detach()
    this.tooltipDisplayChange.emit('hide')
  }

  @HostListener('mousemove', ['$event'])
  handleMouseMove(event: MouseEvent): void {
    if (this.alignment === 'mouse') {
      const elementBoundingBox = this.elementRef.nativeElement.getBoundingClientRect()
      this.relativeMouseCoords = {
        x: event.clientX - elementBoundingBox.x,
        y: event.clientY - elementBoundingBox.y,
      }

      this.mouseMoved$.next()
    }
  }

  ngOnInit(): void {
    this.setupShowHideListeners()
    this.setupCloseOnClickOutside()
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.forceDisplayState$.next(this.forceDisplayState)
    this.updatePosition()
    this.updateTooltipComponent()
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect()
    this.hide()
    this.destroy.next()
    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this.message, 'tooltip')
  }

  private observeTooltipDisplay(): Observable<TooltipDisplay> {
    const visibilitySources: TooltipVisibilitySource[] = [
      {
        source$: this.mouseOver$,
        hideBehaviour: TooltipHideBehaviour.DelayWhenInteractable,
      },
      {
        source$: this.tooltipMouseOver$,
        hideBehaviour: TooltipHideBehaviour.DelayWhenInteractable,
      },
      {
        source$: this.lastClickedOnHostOrTooltip$,
        hideBehaviour: TooltipHideBehaviour.Immediate,
      },
    ]

    // Convert to observables that contain the hide behaviour
    const visibilitySources$ = visibilitySources.map(visibilitySource =>
      visibilitySource.source$.pipe(
        map(active => ({
          active,
          hideBehaviour: visibilitySource.hideBehaviour,
        })),
      ),
    )

    return combineLatest(visibilitySources$).pipe(
      // Make sure we always emit an initial value
      startWith([]),
      pairwise(),
      map(([previous, current]) => {
        const display: TooltipDisplay = current.some(source => source.active) ? 'show' : 'hide'
        const previousActive = previous.filter(source => source.active)

        // Delay hiding when interactable and all previous active sources had the DelayWhenInteractable behaviour
        const delayHide =
          this.interactive &&
          display === 'hide' &&
          previousActive.length &&
          previousActive.every(source => source.hideBehaviour === TooltipHideBehaviour.DelayWhenInteractable)

        return {
          display,
          delayHide,
        }
      }),
      // Delay emission of state when specified so that the user has chance to interact with tooltip before it is hidden
      // Use switch map so that only the latest value is used
      switchMap(displayChange =>
        of(displayChange.display).pipe(delay(displayChange.delayHide ? this.hideTimeDelay : 0)),
      ),
    )
  }

  private setupShowHideListeners(): void {
    combineLatest({
      tooltipDisplay: this.observeTooltipDisplay(),
      forceDisplay: this.forceDisplayState$,
      hideNotOverflowing: this.hideIfNotOverflowing$,
    })
      .pipe(
        map(display => {
          if (display.forceDisplay) {
            return display.forceDisplay
          }
          return display.hideNotOverflowing ? 'hide' : display.tooltipDisplay
        }),
        distinctUntilChanged(),
        takeUntil(this.destroy),
      )
      .subscribe(display => {
        switch (display) {
          case 'show':
            this.show()
            break
          case 'hide':
            this.hide()
            break
          default:
            assertUnreachable(display)
        }
      })
  }

  private setupMouseMovementTooltipUpdating(): void {
    this.mouseMoveSubscription = this.mouseMoved$
      .pipe(
        throttle(() => interval(MOUSE_MOVE_THROTTLE_MS), { leading: true, trailing: true }),
        tap(() => this.updatePosition()),
        takeUntil(this.destroy),
      )
      .subscribe()
  }

  private createOverlay(): void {
    if (this.overlayRef) {
      return
    }

    const strategy = this.getPositionStrategy()

    this.observePositionChanges(strategy)

    this.overlayRef = this.overlay.create({
      positionStrategy: strategy,
      panelClass: "ftr-tooltip-panel'",
      scrollStrategy: this.getScrollStrategy(),
    })
  }

  private updateTooltipComponent(): void {
    if (this.tooltipRef) {
      this.tooltipRef.instance.template = this.template
      this.tooltipRef.instance.templateContext = this.templateContext
      this.tooltipRef.instance.message = this.message
      this.tooltipRef.instance.position = this.position
      this.tooltipRef.instance.alignment = this.alignment
      this.tooltipRef.instance.detectChanges()
    }
  }

  private getScrollStrategy(): RepositionScrollStrategy {
    // For this to work inside scrollable containers the container needs the `cdkScrollable` directive
    return this.overlay.scrollStrategies.reposition({ scrollThrottle: SCROLL_THROTTLE_MS })
  }

  private observePositionChanges(strategy: FlexibleConnectedPositionStrategy): void {
    strategy.positionChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(overlayPosition => this.handleOverlayPositionChange(overlayPosition))
  }

  private handleOverlayPositionChange(overlayPosition: ConnectedOverlayPositionChange): void {
    const panelClass = overlayPosition.connectionPair.panelClass
    if (typeof panelClass === 'string' && panelClass.includes(ALIGNMENTS_CLASS_PREFIX)) {
      this.handlePositionClassChange(panelClass)
    } else if (Array.isArray(panelClass)) {
      const alignmentClass = panelClass.find(classString => classString.includes(ALIGNMENTS_CLASS_PREFIX))
      if (alignmentClass) {
        this.handlePositionClassChange(alignmentClass)
      }
    }
  }

  private getPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withTransformOriginOn('.tooltip')
      .withViewportMargin(VIEWPORT_MARGIN)
      .withScrollableContainers(this.scrollDispatcher.getAncestorScrollContainers(this.elementRef))
      .withPositions([
        getPosition(this.position, this.alignment, this.relativeMouseCoords),
        getPosition(invertPosition(this.position), this.alignment, this.relativeMouseCoords),
      ])
  }

  private handlePositionClassChange(positionString: string): void {
    const [, position, alignment] = positionString.split('-') as [string, TooltipPosition, TooltipAlignment]

    let indicatorPositionStyle = ''
    // Check for auto alignment
    if (alignment === 'auto') {
      const tooltipBounds = this.tooltipRef.instance.tooltip.nativeElement.getBoundingClientRect()
      const hostBounds = this.elementRef.nativeElement.getBoundingClientRect()
      const hostCentre = [hostBounds.x + hostBounds.width / 2, hostBounds.y + hostBounds.height / 2]

      // Position the indicator in line with the centre of the anchor element
      if (position === 'above' || position === 'below') {
        indicatorPositionStyle = `left: ${hostCentre[0] - tooltipBounds.left - 6}px`
      }
      if (position === 'left' || position === 'right') {
        indicatorPositionStyle = `top: ${hostCentre[1] - tooltipBounds.top - 6}px`
      }
    }

    this.tooltipRef.instance.position = position
    this.tooltipRef.instance.alignment = alignment
    this.tooltipRef.instance.indicatorPositionStyle = indicatorPositionStyle
    this.tooltipRef.instance.detectChanges()
  }

  private updatePosition(): void {
    if (this.overlayRef) {
      const position = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy

      position.withPositions([
        getPosition(this.position, this.alignment, this.relativeMouseCoords),
        getPosition(invertPosition(this.position), this.alignment, this.relativeMouseCoords),
      ])

      position.apply()
    }
  }

  /**
   * Unfortunately touching on mobile still fires mouseover events (after the touchend event for taps) but does not
   * reliably fire any mouse leave events. Due to this the tooltips get stuck open when tapping. This function allows
   * checking if a touch start has recently happened, in which case the mouseover can be ignored.
   */
  private isMouseOverNotPartOfTouchEvent(): boolean {
    if (!this.lastTouchStart) {
      return true
    }
    const currentTime = new Date().getTime()
    const diffMs = currentTime - this.lastTouchStart.getTime()
    return diffMs > IGNORE_MOUSE_OVER_AFTER_TOUCH_START_MS
  }
}
