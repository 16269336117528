import { LocalDate, LocalTime } from '@js-joda/core'

export enum RecordingsFilterType {
  Location = 'Location',
  Date = 'Date',
  Department = 'Department',
  AdvancedFilters = 'Advanced Filters',
}

/**
 * Used as a form control name as well as a query string key.
 * Try to keep them short to save some characters in the URL.
 */
export enum RecordingsFilterKey {
  Courthouse = 'c',
  Courtroom = 'cr',
  Department = 'd',
  DateFrom = 'dtf',
  DateTo = 'dtt',
  DateCountInterval = 'dtci',
  DurationFrom = 'df',
  DurationTo = 'dt',
  TimeFrom = 'tf',
  TimeTo = 'tt',
  Tags = 't',
  ReporterLocation = 'r',
}

export enum RecordingsFilterDateCountInterval {
  Month = 'month',
  Relative = 'PT',
  Day = 'day',
  DayRange = 'dayRange',
}

export type RecordingsFilterValueData = Partial<
  Record<RecordingsFilterKey, string | number | string[] | LocalTime | LocalDate>
>

export interface RecordingsFilterDescriptor {
  label: string
  subLabel?: string
}

export type RecordingsFilterLabelData = Partial<Record<RecordingsFilterType, RecordingsFilterDescriptor>>

export interface RecordingsFilterStateModel {
  labels: RecordingsFilterLabelData
  values: RecordingsFilterValueData
}

export enum RelativeDateRangeKey {
  Today = 'PT24H',
  Yesterday = 'PT24H#1',
  ThisWeek = 'PT168H',
  LastWeek = 'PT168H#7',
}
