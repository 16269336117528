import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'
import { Icon, ICONS } from './icon'

/**
 * Encapsulates the available icon sizes, based on the classes provided in icon.component.css
 */
export const ICON_SIZES = {
  ExtraSmall: 'extra-small',
  Text: 'text',
  Small: 'small',
  Regular: 'regular',
  Medium: 'medium',
  Large: 'large',
  ExtraLarge: 'extra-large',
  Title: 'title',
  Double: 'double',
  Jumbo: 'jumbo',
  DoubleTitle: 'double-title',
  Max: 'max',
} as const

export type IconSize = keyof typeof ICON_SIZES

/**
 * Keep these in line with the icon size variables in CSS
 */
const ICON_DIMENSIONS: Record<keyof typeof ICON_SIZES, string> = {
  ExtraSmall: '12',
  Text: '16',
  Small: '18',
  Regular: '20',
  Medium: '22',
  Large: '24',
  ExtraLarge: '28',
  Title: '32',
  Double: '40',
  Jumbo: '46',
  DoubleTitle: '64',
  Max: '84',
}

/**
 * Encapsulates the available icon colors, based on classes provided in icon.component.css
 */
export const ICON_COLORS = {
  Brand: 'brand',
  BrandDarker: 'brand-darker',
  Primary: 'primary',
  Secondary: 'secondary',
  Grey: 'grey',
  GreyLighter: 'grey-lighter',
  GreyLight: 'grey-light',
  Grey30: 'grey-30',
  White: 'white',
  Apple: 'apple',
  Lemon: 'lemon',
  Blueberry: 'blueberry',
  Aqua: 'aqua',
  Mandarin: 'mandarin',
  Watermelon: 'watermelon',
} as const

export type IconColor = keyof typeof ICON_COLORS

export const ICON_ALIGNMENTS = {
  Baseline: 'baseline',
  TextTop: 'text-top',
  TextBottom: 'text-bottom',
  Middle: 'middle',
  Top: 'top',
  Bottom: 'bottom',
  Sub: 'sub',
} as const

export type IconAlignment = keyof typeof ICON_ALIGNMENTS

export const ICON_GAPS = {
  Regular: 'regular',
  ThreeQuarter: 'three-quarter',
  Small: 'small',
  Tiny: 'tiny',
} as const

export type IconGap = keyof typeof ICON_GAPS

const CLASS_PREFIX = 'icon'

/**
 * A wrapper for a svg element, including optional rendering parameters.
 */
@Component({
  standalone: true,
  selector: 'ftr-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  icon = input.required<Icon>()

  /**
   * An optional size.
   * According to the design system, the size of the icon depends on the size of the text it is used beside.
   */
  size = input<IconSize>('Large')

  /**
   * An optional color, otherwise the icon will inherit the text `color` of its parent (or body text).
   */
  color = input<IconColor | undefined>()

  /**
   * Icon vertical alignments with text.
   * Different sized text with different style icons seem to require different alignment
   */
  align = input<IconAlignment>('Baseline')

  /**
   * Include a margin half the dimensions of the icon to the right.
   */
  gapRight = input<IconGap | undefined>()

  /**
   * Include a margin half the dimensions of the icon to the left.
   */
  gapLeft = input<IconGap | undefined>()

  /**
   * Whether the icon is interactive and applies its own hover/active states.
   */
  interactive = input(false)

  /**
   * An optional aria-label attribute
   */
  label = input<string | undefined>()

  /**
   * Whether this icon is hidden from screen readers
   * This is useful for icons that are purely decorative and have no meaning
   */
  ariaHidden = input<boolean | undefined>(undefined)

  protected iconUrl = computed(() => `#${ICONS[this.icon()]}`)
  protected ariaLabel = computed(() => (!this.ariaHidden() ? this.label() || this.icon() : undefined))

  protected classNames = computed(() => {
    const sizeValue = ICON_SIZES[this.size()]
    const colorValue = this.color() && ICON_COLORS[this.color()!]
    const alignValue = ICON_ALIGNMENTS[this.align()]
    const gapRightValue = this.gapRight() && ICON_GAPS[this.gapRight()!]
    const gapLeftValue = this.gapLeft() && ICON_GAPS[this.gapLeft()!]
    const interactive = this.interactive()
    return [
      CLASS_PREFIX,
      this.addPrefix(sizeValue),
      colorValue && this.addPrefix(colorValue),
      this.addPrefix(alignValue),
      gapRightValue && this.addPrefix(sizeValue, `gap-${gapRightValue}`),
      gapLeftValue && this.addPrefix(sizeValue, `gap-left-${gapLeftValue}`),
      interactive && colorValue && this.addPrefix(colorValue, 'interactive'),
    ].filter(v => typeof v === 'string')
  })

  protected iconDimensions = ICON_DIMENSIONS

  private addPrefix(...classNames: string[]): string {
    return `${CLASS_PREFIX}__${classNames.join('--')}`
  }
}
