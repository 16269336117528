import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { ComposableDropdownRenderMode } from './ComposableDropdownRenderMode'
import { ComposableDropdownBodyComponent } from './composable-dropdown-body.component'
import { ComposableDropdownHeaderComponent } from './composable-dropdown-header/composable-dropdown-header.component'

@Component({
  selector: 'ftr-composable-dropdown',
  templateUrl: './composable-dropdown.component.html',
  styleUrls: ['./composable-dropdown.component.css'],
})
export class ComposableDropdownComponent implements AfterViewInit, OnChanges {
  @ContentChild(ComposableDropdownHeaderComponent) header: ComposableDropdownHeaderComponent
  @ContentChild(ComposableDropdownBodyComponent) body: ComposableDropdownBodyComponent

  @Input() renderMode: ComposableDropdownRenderMode = 'drop-down'

  // Set false to require the user to press the return key to open the dropdown
  @Input() showOnFocus = true

  @Output() focusEvent = new EventEmitter<FocusEvent>()

  private hideTimer: ReturnType<typeof setTimeout>
  private isDropDownShown = false

  ngAfterViewInit(): void {
    this.header.setRenderMode(this.renderMode)
    this.body.setRenderMode(this.renderMode)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.renderMode && this.header && this.body) {
      this.header.setRenderMode(this.renderMode)
      this.body.setRenderMode(this.renderMode)
    }
  }

  onFocus(event: FocusEvent): void {
    if (!this.showOnFocus && !this.isDropDownShown) {
      return
    }

    if (this.renderMode === 'drop-down') {
      if (event.type === 'focusin') {
        this.showDropDown()
      }
      if (event.type === 'focusout') {
        this.hideDropDown()
      }
    }
    this.focusEvent.emit(event)
  }

  toggleDropdown(): void {
    if (this.showOnFocus) {
      return
    }

    if (this.renderMode === 'drop-down') {
      if (this.isDropDownShown) {
        this.hideDropDown()
      } else {
        this.showDropDown()
      }
    }
  }

  showDropDown(): void {
    if (this.hideTimer) {
      clearInterval(this.hideTimer)
    }
    this.hideTimer = setTimeout(() => {
      this.isDropDownShown = true
      this.body.shouldShowDropDown.next(true)
    }, 0)
  }

  hideDropDown(): void {
    if (this.hideTimer) {
      clearInterval(this.hideTimer)
    }

    this.hideTimer = setTimeout(() => {
      this.isDropDownShown = false
      this.body.shouldShowDropDown.next(false)
    }, 50)
  }
}
