<input
  #elm
  [id]="id"
  class="input__control"
  [ngClass]="{ 'input__control--disabled': control.disabled, 'input__control--has-value': hasValue }"
  type="checkbox"
  [required]="required"
  [attr.name]="name"
  [formControl]="control"
  [attr.aria-invalid]="control.touched && !control.valid"
  [attr.aria-labelledby]="labelledBy"
  [attr.data-testid]="testId"
/>
<label
  class="input__label"
  [class.indeterminate]="indeterminate"
  [class.label-full-width]="fullWidth"
  [class.label-bold]="isTitleBold"
  [id]="id + 'Label'"
  [for]="id"
>
  <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  @if (label) {
    {{ label }}
  }
</label>

@if (summaryTemplate || summary) {
  <span class="input__summary" [id]="id + 'Summary'">
    @if (summaryTemplate) {
      <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
    }
    @if (summary && !summaryTemplate) {
      {{ summary }}
    }
  </span>
}
<span
  role="alert"
  class="input__validation"
  ftrValidationErrorHint
  [label]="validationLabel || label || ''"
  [ngFormControl]="control"
  [controlTouched]="control.touched"
></span>
