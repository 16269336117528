<div
  class="drop-down"
  (focusout)="onFocus($event)"
  (focusin)="onFocus($event)"
  (keydown.escape)="hideDropDown()"
  (keydown.enter)="toggleDropdown()"
  (click)="toggleDropdown()"
  [attr.tabindex]="renderMode === 'drop-down' ? -1 : null"
>
  <ng-content></ng-content>
</div>
