@if (card | async; as c) {
  <div
    class="input payment _lr-hide"
    [ngClass]="{
      'input--highlight': highlight | async,
      'input--invalid': (c.isNotAsked() && empty) || c.isFailure(),
      'input--touched': touched || control.touched,
    }"
  >
    <div #cardContainer class="input__control input__control--container"></div>
    <label [for]="cardContainer" class="input__label">Card Details</label>
    <span class="input__bar"></span>
    <ftr-remote
      [remote]="c"
      [loadingTemplate]="loading"
      [failureTemplate]="failure"
      [emptyTemplate]="emptyTemplate"
      [notAskedTemplate]="emptyTemplate"
    >
      <ng-template #loading>
        <div class="payment__bar-loader">
          <ftr-line-loader class="payment__loader" message="" [collapse]="true"></ftr-line-loader>
        </div>
        <span class="input__helper">Verifying card details...</span>
      </ng-template>
      <ng-template #failure let-error>
        <span class="input__validation">{{ error.message }}</span>
      </ng-template>
      <ng-template #emptyTemplate>
        @if ((touched || control.touched) && (empty || !complete)) {
          <span class="input__validation">Please enter your card details.</span>
        }
      </ng-template>
    </ftr-remote>
  </div>
}
