import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { PrivateConfiguration, UpdateConfigurationBody } from '@ftr/contracts/api/configuration'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, mapData } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import { CoreConfigurationService } from '../core-configuration.service'

export interface PersonalizationLogoUploadProps {
  courtSystemId: Uuid
}

export interface PersonalizationLogoUploadResponse {
  publicUrl: string
}

export interface PersonalizationConfiguration {
  id: Uuid
  countryCode?: string
  stateCode?: string | null
  logoUrl?: string
  ordersHelpEmail?: string
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurePersonalizationService {
  private readonly apiClient: ApiClient

  constructor(
    apiClientFactory: ApiClientFactory,
    private readonly coreConfigurationService: CoreConfigurationService,
  ) {
    this.apiClient = apiClientFactory.build('/configuration')
  }

  getPersonalizationConfiguration(courtSystemId: Uuid): ApiResult<PersonalizationConfiguration> {
    return this.coreConfigurationService
      .getExtendedConfigByCourtSystem(courtSystemId)
      .pipe(mapData(configuration => mapConfigurationToPersonalizationConfiguration(configuration)))
  }

  update(
    configurationId: Uuid,
    countryCode: string,
    stateCode: string | undefined,
    logoUrl: string | undefined,
    ordersHelpEmail: string,
  ): ApiResult {
    const update = new UpdateConfigurationBody()

    if (countryCode) {
      update.countryCode = countryCode
      update.stateCode = stateCode || null
    }
    update.ordersHelpEmail = ordersHelpEmail

    update.logoUrl = logoUrl || null

    return this.apiClient.patch({
      path: `/${configurationId}`,
      body: classToPlain(update),
    })
  }
}

function mapConfigurationToPersonalizationConfiguration(
  configuration: PrivateConfiguration,
): PersonalizationConfiguration {
  const { id, countryCode, stateCode, logoUrl, ordersHelpEmail } = configuration
  return { id, countryCode, stateCode, logoUrl, ordersHelpEmail }
}
