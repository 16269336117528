<nav
  class="side-nav"
  [ngClass]="{ 'side-nav--collapsed': !expanded }"
  data-track
  [attr.data-track-group]="currentCourtSystemId ? 'side-nav-internal' : 'side-nav-external'"
  aria-label="Main navigation"
>
  <a class="side-nav__skip-nav" href="javascript:;" onclick="document.location.hash='main-content';"
    >Skip to main content</a
  >
  @if (selectedNestedItem()) {
    <div class="branch-side-nav">
      <div class="branch-side-nav__header">
        <ng-container *ngTemplateOutlet="menuCloseButtonTemplate"></ng-container>
        <button
          class="branch-side-nav__title branch-side-nav__btn"
          type="button"
          ftrButton
          color="grey"
          [fullWidth]="true"
          [title]="selectedNestedItem()?.title"
          (click)="popNestedNavItems()"
        >
          <ftr-icon [icon]="'KeyboardArrowLeft'" [size]="'Small'"></ftr-icon>
          <div class="side-nav__item-title">
            {{ selectedNestedItem()?.title }}
          </div>
        </button>
      </div>
      <div class="branch-side-nav__body">
        @for (navItem of selectedNestedItem()?.children; track trackBy($index, navItem)) {
          <ng-container *ngTemplateOutlet="navItemTemplate; context: { $implicit: navItem }"></ng-container>
        }
      </div>
    </div>
  } @else {
    <div class="side-nav__header">
      @if (displayMode === 'OVERLAY') {
        <ng-container *ngTemplateOutlet="menuCloseButtonTemplate"></ng-container>
      }
      @if (isLoading) {
        <div class="side-nav__header-loading" [ngClass]="{ 'side-nav__header-loading-collapsed': !expanded }">
          <div class="side-nav__loading-circle" [ngClass]="{ 'side-nav__loading-circle-collapsed': !expanded }"></div>
          <div class="side-nav__loading" [ngClass]="{ 'side-nav__loading-collapsed': !expanded }"></div>
        </div>
      } @else {
        <ftr-court-system-dropdown
          [currentCourtSystemId]="currentCourtSystemId"
          [collapsed]="!expanded"
          [displayMode]="displayMode"
          (dropdownOpened)="onCourtSystemDropdownOpened($event)"
        ></ftr-court-system-dropdown>

        @if (displayMode === 'DEFAULT') {
          <button
            class="side-nav__expand-btn"
            type="button"
            ftrButton
            displayType="icon"
            [autoWidth]="true"
            (click)="expandedToggled.emit()"
            ftrTooltip
            [title]="expanded ? 'Collapse Navigation Menu' : 'Expand Navigation Menu'"
            [attr.aria-label]="expanded ? 'Collapse Navigation Menu' : 'Expand Navigation Menu'"
            [message]="expanded ? 'Collapse' : 'Expand'"
            [showDash]="false"
            position="right"
            [forceDisplayState]="isExpandButtonHovered() ? undefined : 'hide'"
            (mouseenter)="isExpandButtonHovered.set(true)"
            (mouseleave)="isExpandButtonHovered.set(false)"
          >
            <ftr-icon
              [icon]="expanded ? 'KeyboardArrowLeft' : 'KeyboardArrowRight'"
              [title]="expanded ? 'Collapse Navigation' : 'Expand Navigation'"
              [align]="'TextTop'"
              [color]="'White'"
            ></ftr-icon>
          </button>
        }
      }
    </div>
    <div class="side-nav__body">
      @if (isLoading) {
        @for (loadingNavGroup of loadingSideNavGroups; track trackBy($index, loadingNavGroup)) {
          <div class="side-nav__group">
            <ng-container
              *ngTemplateOutlet="navGroupLoadingTemplate; context: { $implicit: loadingNavGroup }"
            ></ng-container>
          </div>
        }
      } @else {
        @for (navGroup of navGroups(); track trackBy($index, navGroup)) {
          <div class="side-nav__group" [class.side-nav__group--branch]="selectedNestedItem()">
            <div class="side-nav__group-header">
              @if (navGroup.title; as navGroupTitle) {
                <div class="side-nav__group-title" [ngClass]="{ 'side-nav__group-title--collapsed': !expanded }">
                  {{ navGroupTitle }}
                </div>
              }
              @if (navGroup.title === 'Favorites') {
                <ftr-favorites-menu
                  [courtSystemId]="currentCourtSystemId!"
                  [collapsed]="!expanded"
                  [displayMode]="displayMode"
                  (dropDownOpened)="onFavoritesDropdownOpened($event)"
                ></ftr-favorites-menu>
              }
            </div>
            @if (!navGroup.isLoading) {
              <ng-container *ngTemplateOutlet="navGroupTemplate; context: { $implicit: navGroup }"></ng-container>
            } @else {
              <ng-container
                *ngTemplateOutlet="navGroupLoadingTemplate; context: { $implicit: navGroup }"
              ></ng-container>
            }
          </div>
        }
      }
    </div>
    <div class="side-nav__footer">
      <div class="side-nav__group">
        <div class="side-nav__group-body">
          @for (footerItem of footerItems(); track footerItem) {
            @if (!footerItem.displayMode || footerItem.displayMode === displayMode) {
              <ng-container *ngTemplateOutlet="navItemTemplate; context: { $implicit: footerItem }"></ng-container>
            }
          }
          @if (displayMode === 'DEFAULT') {
            <ng-container *ngTemplateOutlet="helpAndSupportNavItem"></ng-container>
          }
        </div>
      </div>
    </div>
  }

  <!-- We need to include the favorites editing modal whether or not we are in branch rendering mode -->
  @if (currentCourtSystemId) {
    <ftr-favorites-edit-modal [courtSystemId]="currentCourtSystemId"></ftr-favorites-edit-modal>
  }
</nav>

<ng-template #navGroupTemplate let-navGroup>
  <div class="side-nav__group-body">
    @for (navItem of navGroup.items; track trackBy($index, navItem)) {
      @if (!navItem.displayMode || navItem.displayMode === displayMode) {
        <ng-container
          *ngTemplateOutlet="navItemTemplate; context: { $implicit: navItem, groupTitle: navGroup.title }"
        ></ng-container>
      }
    }
  </div>
</ng-template>

<ng-template #navGroupLoadingTemplate let-navGroup>
  <div class="side-nav__group-body">
    @for (idx of [33, 66, 99]; track idx) {
      <div
        class="side-nav__loading"
        [ngClass]="{ 'side-nav__loading-collapsed': !expanded }"
        [style.width]="
          expanded ? loadingWidths[((navGroup.title ? navGroup.title.length : 10) * idx) % loadingWidths.length] : null
        "
      ></div>
    }
  </div>
</ng-template>

<ng-template #navItemTemplate let-navItem let-groupTitle="groupTitle">
  @if (navItem.type === 'LINK') {
    <a
      class="side-nav__item"
      [title]="navItem.title"
      ftrTooltip
      [attr.aria-label]="
        'Main Navigation, ' + (groupTitle ? ' Group ' + groupTitle + ', ' : '') + ' Link ' + navItem.title
      "
      [message]="navItem.tooltipHint || navItem.title"
      [showDash]="false"
      [position]="displayMode === 'OVERLAY' ? 'above' : 'right'"
      [forceDisplayState]="displayMode === 'OVERLAY' && !isOpened ? 'hide' : undefined"
      [hideIfNotOverflowing]="expanded || isOpened"
      (click)="handleOnNavItemClick(navItem)"
      [routerLink]="navItem.path"
      [queryParams]="navItem.queryParams"
      [routerLinkActiveOptions]="{
        paths: 'exact',
        matrixParams: 'ignored',
        queryParams: groupTitle === 'Favorites' ? 'exact' : 'ignored',
        fragment: 'ignored',
      }"
      [routerLinkActive]="'side-nav__item--active'"
    >
      <ng-container *ngTemplateOutlet="navItemInner; context: { $implicit: navItem }"></ng-container>
    </a>
  } @else {
    <button
      class="side-nav__item"
      [class.elevio__help]="navItem.title === helpCenterNavTitle"
      type="button"
      ftrButton
      color="grey"
      [fullWidth]="true"
      [title]="navItem.title"
      ftrTooltip
      [message]="navItem.tooltipHint || navItem.title"
      [showDash]="false"
      [position]="displayMode === 'OVERLAY' ? 'above' : 'right'"
      [forceDisplayState]="displayMode === 'OVERLAY' && !isOpened ? 'hide' : undefined"
      [hideIfNotOverflowing]="expanded || isOpened"
      (click)="handleOnNavItemClick(navItem)"
    >
      <ng-container *ngTemplateOutlet="navItemInner; context: { $implicit: navItem }"></ng-container>
      @if (navItem.type === 'BRANCH') {
        <ftr-icon class="side-nav__item-end" [icon]="'KeyboardArrowRight'" [size]="'Small'"></ftr-icon>
      }
    </button>
  }
</ng-template>

<ng-template #navItemInner let-navItem>
  @if (navItem.logoUrl) {
    <img [src]="navItem.logoUrl" [alt]="'Logo for ' + navItem.title" />
  } @else if (navItem.icon) {
    <ftr-icon
      [icon]="navItem.icon"
      [size]="'Small'"
      [title]="expanded ? navItem.title : navItem.tooltipHint || navItem.title"
    ></ftr-icon>
  }
  <div class="side-nav__item-title-wrapper" [ngClass]="{ 'side-nav__item-title-wrapper--collapsed': !expanded }">
    <div class="side-nav__item-title">
      {{ navItem.title }}
    </div>
    <div class="side-nav__item-title-spacer"></div>
  </div>
</ng-template>

<ng-template #helpAndSupportNavItem let-navItem>
  <button
    class="side-nav__item"
    type="button"
    ftrButton
    color="grey"
    [fullWidth]="true"
    [title]="'Help & Support'"
    ftrTooltip
    [message]="'Help & Support'"
    [showDash]="false"
    position="right"
    [forceDisplayState]="expanded ? 'hide' : undefined"
    (click)="helpAndSupportPopover.toggle()"
    #helpAndSupportPopover="ngbPopover"
    [ngbPopover]="helpAndSupportTemplate"
    [animation]="false"
    [triggers]="'manual'"
  >
    <ng-container
      *ngTemplateOutlet="navItemInner; context: { $implicit: { icon: 'HelpCircle', title: 'Help & Support' } }"
    ></ng-container>
  </button>
</ng-template>

<ng-template #helpAndSupportTemplate>
  <ftr-help-sub-menu-flyout-card></ftr-help-sub-menu-flyout-card>
</ng-template>

<ng-template #menuCloseButtonTemplate>
  <button
    class="branch-side-nav__btn"
    type="button"
    ftrButton
    color="grey"
    [autoWidth]="true"
    (click)="closeSideBar()"
    title="Collapse Navigation Menu"
    aria-label="Collapse Navigation Menu"
  >
    <ftr-icon icon="Close" align="Middle" size="Regular"></ftr-icon>
  </button>
</ng-template>
