// Ensure these are in sync with /libs/foundation/css/variables/colors.css
export enum Colors {
  Black = '#000',
  White = '#fff',

  Grey90 = '#0d0d0d',
  Grey80 = '#1a1a1a',
  Grey70 = '#282828',
  Grey60 = '#353535',
  Grey = '#424242',
  Grey40 = '#707070',
  Grey30 = '#ababab',
  Grey20 = '#e5e6e6',
  Grey10 = '#f6f7f8',
  GreyLighter = '#d4d4d4',

  Error10 = '#fdeae9',
  Error20 = '#ffc3c3',
  Error30 = '#fc8f8d',
  Error40 = '#f95553',
  Error = '#dd201d',
  Error60 = '#c01e1c',
  Error70 = '#8b1715',
  Error80 = '#621110',
  Error90 = '#420e0d',

  Brand10 = '#fde4e3',
  Brand20 = '#f9b2b0',
  Brand30 = '#f58b88',
  Brand40 = '#f26561',
  Brand = '#ef3e39',
  Brand60 = '#bf322e',
  Brand70 = '#8f2522',
  Brand80 = '#601917',
  Brand90 = '#481311',

  Primary10 = '#e5f5ff',
  Primary20 = '#bce3ff',
  Primary30 = '#93d0ff',
  Primary40 = '#5a9fe9',
  Primary = '#206dd2',
  Primary60 = '#1058b8',
  Primary70 = '#00429d',
  Primary80 = '#003475',
  Primary90 = '#00254d',

  Secondary10 = '#e1f2f1',
  Secondary20 = '#cbe9e6',
  Secondary30 = '#b5e0db',
  Secondary40 = '#6bb2a7',
  Secondary = '#218372',
  Secondary60 = '#1f7868',
  Secondary70 = '#1d6c5e',
  Secondary80 = '#164e44',
  Secondary90 = '#0e2f29',

  Watermelon10 = '#fee9ea',
  Watermelon20 = '#febec3',
  Watermelon30 = '#fd9ea6',
  Watermelon40 = '#fd7d88',
  Watermelon = '#fc5d6a',
  Watermelon60 = '#ca4a55',
  Watermelon70 = '#973840',
  Watermelon80 = '#65252a',
  Watermelon90 = '#4c1c20',

  Mandarin10 = '#ffede0',
  Mandarin20 = '#ffceaa',
  Mandarin30 = '#ffb175',
  Mandarin40 = '#ff9d51',
  Mandarin = '#ff872c',
  Mandarin60 = '#db6b16',
  Mandarin70 = '#bd5d13',
  Mandarin80 = '#76390c',
  Mandarin90 = '#542807',

  Lemon10 = '#fff7d7',
  Lemon20 = '#fdedb4',
  Lemon30 = '#fce38e',
  Lemon40 = '#fbda69',
  Lemon = '#fad143',
  Lemon60 = '#ad902f',
  Lemon70 = '#857024',
  Lemon80 = '#64541b',
  Lemon90 = '#4b3f14',

  Apple10 = '#e7f3e2',
  Apple20 = '#badcad',
  Apple30 = '#97cb85',
  Apple40 = '#75b95c',
  Apple = '#52a833',
  Apple60 = '#428629',
  Apple70 = '#31651f',
  Apple80 = '#214314',
  Apple90 = '#19320f',

  Aqua10 = '#e0f2ee',
  Aqua20 = '#a5dace',
  Aqua30 = '#78c8b6',
  Aqua40 = '#4bb59d',
  Aqua = '#1ea385',
  Aqua60 = '#18826a',
  Aqua70 = '#126250',
  Aqua80 = '#0c4135',
  Aqua90 = '#093128',

  Blueberry10 = '#deeffc',
  Blueberry20 = '#a0d1f6',
  Blueberry30 = '#71baf1',
  Blueberry40 = '#41a3ed',
  Blueberry = '#128ce8',
  Blueberry60 = '#0e70ba',
  Blueberry70 = '#0b548b',
  Blueberry80 = '#07385d',
  Blueberry90 = '#052a46',

  Grape10 = '#efe7ff',
  Grape20 = '#d1b9ff',
  Grape30 = '#bb95ff',
  Grape40 = '#a472ff',
  Grape = '#8d4fff',
  Grape60 = '#713fcc',
  Grape70 = '#552f99',
  Grape80 = '#382066',
  Grape90 = '#2a184c',
}
