import { Injectable, Signal } from '@angular/core'
import { RemoteData, selectWith } from '@ftr/foundation'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { memoize } from 'lodash-es'
import { FeatureFlagKeyOfType, LaunchDarklyFlagValue, WebFeatureFlags } from '../feature-flags'
import { SetFeatureFlagValues } from './feature-flag.actions'
import { FeatureFlagStateModel } from './feature-flag.model'

export function defaultFeatureFlagState(): FeatureFlagStateModel {
  return {
    featureFlags: {
      'test-boolean-flag': false,
      'facebook-sso': false,
      'recording-vault-support': false,
      'recording-vault-activity-log-liveness': false,
      'disable-chromium-remapping-hack': false,
      'feature-disable-channel-remapping': false,
      'real-time-stt-attribution-approach': 'never-use-software-based-attribution',
      'design-system-overlay': false,
      'detailed-error-messages': false,
      'rth-feature-show-infrastructure-status': false,
      'rth-hybrid-audio-routing': false,
      'rth-courtroom-display': false,
      'offline-real-time-recordings': false,
      'streaming-server-audio-emission': false,
      'new-regional-playback-api': false,
      'feature-courtroom-calendar': false,
      'release-real-time-exports': false,
      'release-license-configuration': false,
      'trm-recording-channel-map-strategy': 0,
      'rth-join-courtroom-remotely': false,
      'realtime-multichannel-secondary-content': false,
      'realtime-intelligent-playback-layout': false,
      'realtime-intelligent-video-splitting': false,
      'stt-debug-ui-tooling': false,
      'feature-cases-hearings-audit-update-nov-24': false,
      'stt-responsive-container-layout': false,
      'rth-schedule-proceedings': false,
      'rth-configure-video-devices': false,
      'feature-department-activity-pages': false,
      'co-department-settings': false,
      'new-audit-export-structure': false,
      'feature-contact-details': false,
      'feature-people-judges-setup': false,
      'feature-courthouse-courtroom-add': false,
      'audit-location': false,
    },
    hasInitializedNonDefaultFlagValues: false,
  }
}

@State<FeatureFlagStateModel>({
  name: 'featureFlagState',
  defaults: defaultFeatureFlagState(),
})
@Injectable()
export class FeatureFlagState {
  @Selector()
  static allFeatureFlags(state: FeatureFlagStateModel): WebFeatureFlags {
    return state.featureFlags
  }

  static featureFlag = memoize(
    <T extends LaunchDarklyFlagValue = boolean>(
      key: FeatureFlagKeyOfType<T>,
    ): ((featureFlags: ReturnType<typeof FeatureFlagState.allFeatureFlags>) => T) => {
      return createSelector(
        [FeatureFlagState.allFeatureFlags],
        featureFlags => featureFlags[key as keyof WebFeatureFlags] as T,
      )
    },
  )

  static resolvedFeatureFlagValue = memoize(
    <T extends LaunchDarklyFlagValue = boolean>(
      key: FeatureFlagKeyOfType<T>,
    ): ((state: FeatureFlagStateModel) => RemoteData<T>) => {
      return createSelector([FeatureFlagState], state => {
        if (!state.hasInitializedNonDefaultFlagValues) {
          return RemoteData.loading()
        }
        return RemoteData.success(state.featureFlags[key as keyof WebFeatureFlags] as T)
      })
    },
  )

  @Action(SetFeatureFlagValues)
  setFeatureFlagValues(
    { patchState, getState }: StateContext<FeatureFlagStateModel>,
    { featureFlags, hasInitializedNonDefaultFlagValues }: SetFeatureFlagValues,
  ): void {
    const { featureFlags: currentFlags } = getState()
    const mergeFlags = <T extends LaunchDarklyFlagValue>(flagName: FeatureFlagKeyOfType<T>): T =>
      (featureFlags[flagName as keyof WebFeatureFlags] as T | undefined) ??
      (currentFlags[flagName as keyof WebFeatureFlags] as T)

    patchState({
      featureFlags: {
        'test-boolean-flag': mergeFlags('test-boolean-flag'),
        'recording-vault-support': mergeFlags('recording-vault-support'),
        'facebook-sso': mergeFlags('facebook-sso'),
        'recording-vault-activity-log-liveness': mergeFlags('recording-vault-activity-log-liveness'),
        'disable-chromium-remapping-hack': mergeFlags('disable-chromium-remapping-hack'),
        'feature-disable-channel-remapping': mergeFlags('feature-disable-channel-remapping'),
        'real-time-stt-attribution-approach': mergeFlags('real-time-stt-attribution-approach'),
        'design-system-overlay': mergeFlags('design-system-overlay'),
        'detailed-error-messages': mergeFlags('detailed-error-messages'),
        'rth-feature-show-infrastructure-status': mergeFlags('rth-feature-show-infrastructure-status'),
        'rth-hybrid-audio-routing': mergeFlags('rth-hybrid-audio-routing'),
        'rth-courtroom-display': mergeFlags('rth-courtroom-display'),
        'offline-real-time-recordings': mergeFlags('offline-real-time-recordings'),
        'streaming-server-audio-emission': mergeFlags('streaming-server-audio-emission'),
        'new-regional-playback-api': mergeFlags('new-regional-playback-api'),
        'feature-courtroom-calendar': mergeFlags('feature-courtroom-calendar'),
        'release-real-time-exports': mergeFlags('release-real-time-exports'),
        'release-license-configuration': mergeFlags('release-license-configuration'),
        'trm-recording-channel-map-strategy': mergeFlags('trm-recording-channel-map-strategy'),
        'rth-join-courtroom-remotely': mergeFlags('rth-join-courtroom-remotely'),
        'realtime-multichannel-secondary-content': mergeFlags('realtime-multichannel-secondary-content'),
        'realtime-intelligent-playback-layout': mergeFlags('realtime-intelligent-playback-layout'),
        'realtime-intelligent-video-splitting': mergeFlags('realtime-intelligent-video-splitting'),
        'stt-debug-ui-tooling': mergeFlags('stt-debug-ui-tooling'),
        'feature-cases-hearings-audit-update-nov-24': mergeFlags('feature-cases-hearings-audit-update-nov-24'),
        'stt-responsive-container-layout': mergeFlags('stt-responsive-container-layout'),
        'rth-schedule-proceedings': mergeFlags('rth-schedule-proceedings'),
        'rth-configure-video-devices': mergeFlags('rth-configure-video-devices'),
        'co-department-settings': mergeFlags('co-department-settings'),
        'feature-department-activity-pages': mergeFlags('feature-department-activity-pages'),
        'new-audit-export-structure': mergeFlags('new-audit-export-structure'),
        'feature-contact-details': mergeFlags('feature-contact-details'),
        'feature-people-judges-setup': mergeFlags('feature-people-judges-setup'),
        'feature-courthouse-courtroom-add': mergeFlags('feature-courthouse-courtroom-add'),
        'audit-location': mergeFlags('audit-location'),
      },
      hasInitializedNonDefaultFlagValues,
    })
  }
}

/**
 * Wrapper over the feature flag select which provide a signal for a specific feature flag.
 * @see import('../testing/jest/mock-feature-flag').mockFeatureFlag
 */
export function featureFlag<K extends keyof WebFeatureFlags>(key: K): Signal<WebFeatureFlags[K]> {
  return selectWith(() => FeatureFlagState.featureFlag(key as FeatureFlagKeyOfType<any>))
}
