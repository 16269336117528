<svg
  role="img"
  [ngClass]="classNames()"
  [attr.aria-label]="ariaLabel()"
  [attr.aria-hidden]="ariaHidden()"
  [attr.height]="iconDimensions[size()]"
  [attr.width]="iconDimensions[size()]"
>
  <use [attr.xlink:href]="iconUrl()"></use>
</svg>
