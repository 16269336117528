<div class="textarea__container" [class.textarea__container--close-to-maxlength]="isCloseToMaxLength()">
  <div class="textarea" [class.textarea--invalid]="ngFormControl.touched && !ngFormControl.valid">
    <div class="textarea__inner">
      <textarea
        #textarea
        [id]="name"
        [maxLength]="maxLength"
        class="textarea__control"
        [class.textarea__control--has-value]="hasValue"
        [class.textarea__control--has-label]="!!label"
        [required]="required"
        [attr.name]="name"
        [attr.placeholder]="placeholder"
        [formControl]="ngFormControl"
        [attr.aria-invalid]="ngFormControl.touched && !ngFormControl.valid"
        [attr.data-notrack]="notrack"
      >
      </textarea>
      @if (label) {
        <label class="textarea__label" [for]="name">{{ label }}</label>
      }
    </div>
  </div>
  @if (showMarkdownHint || isMaxLength()) {
    <div class="textarea__hint">
      @if (showMarkdownHint) {
        <a class="textarea__markdown" href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
          <ftr-icon icon="HelpCircle" color="Primary" [interactive]="true" align="Top" size="Medium"> </ftr-icon>
          Markdown is supported (except external images)
        </a>
      }
      <div
        class="textarea__maxlength"
        [class.textarea__maxlength--is-maxlength]="isMaxLength()"
        [attr.role]="isMaxLength() ? 'alert' : 'status'"
      >
        <ftr-icon icon="Error" size="Small" align="Sub" gapRight="Small"></ftr-icon>
        {{ maxLength | number }} characters max.
      </div>
    </div>
  }
  <div
    role="alert"
    class="textarea__validation"
    ftrValidationErrorHint
    [label]="validationLabel || label || 'this field'"
    [ngFormControl]="ngFormControl"
    [controlTouched]="ngFormControl.touched"
    [submitAttempted]="submitAttempted"
  ></div>
</div>
