// eslint-disable-next-line max-classes-per-file
import { JudgeRole } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'

export const JUDGE_ROLES_ACTION_SCOPE = '[Judge Roles List]'
export class InitialiseJudgeRolesListCommand {
  static readonly type = `${JUDGE_ROLES_ACTION_SCOPE} Initialise Judge Roles List`
  constructor(readonly courtSystemId: Uuid) {}
}

export class UpdateJudgeRolesListCommand {
  static readonly type = `${JUDGE_ROLES_ACTION_SCOPE} Update Judge Roles List`
  constructor(
    public courtSystemId: Uuid,
    public judgeRoles: JudgeRole[],
  ) {}
}

export class FetchJudgeRolesListPageCommand {
  static readonly type = `${JUDGE_ROLES_ACTION_SCOPE} Fetch Judge Roles Page`
  constructor(readonly courtSystemId: string) {}
}

export class AddJudgeRoleCommand {
  static readonly type = `${JUDGE_ROLES_ACTION_SCOPE} Add Judge Role`
  constructor(
    readonly courtSystemId: string,
    readonly roleName: string,
  ) {}
}
