import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { KeyboardNavigationCard } from '@ftr/foundation'

@Component({
  selector: 'ftr-filter-list-card',
  templateUrl: './filter-list-card.component.html',
  styleUrls: ['./filter-list-card.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class FilterListCardComponent extends KeyboardNavigationCard {
  @Input() commonSelector: string = '.filter-list-card'
  @Input() className?: string = ''
  @Input() displayMode?: 'DEFAULT' | 'COMPACT' = 'DEFAULT'

  @Output() select = new EventEmitter<Event>()

  handleOnClick($event: MouseEvent): void {
    this.select.emit($event)
  }

  handleOnEnter($event: Event): void {
    this.select.emit($event)
  }
}
