<div class="basic-checkbox__container">
  <input
    [id]="id"
    class="input__control"
    [ngClass]="{
      'input__control--has-value': value,
      'input__control--disabled': disabled,
      'input__control--title-bold': isTitleBold,
    }"
    type="checkbox"
    [disabled]="disabled"
    [attr.disabled]="disabled ? true : null"
    [attr.name]="name"
    [attr.aria-labelledby]="labelName"
    [attr.aria-describedby]="descriptionName"
    [ngModel]="getBooleanValue()"
    (ngModelChange)="onChange($event)"
  />
  <label
    class="input__label"
    [ngClass]="{ indeterminate: value === 'indeterminate' ? true : null }"
    [id]="labelName"
    [for]="id"
  >
    @if (icon) {
      <ftr-icon [icon]="icon" gapRight="Small" align="Sub"></ftr-icon>
    }
    {{ name }}
  </label>
  <span [id]="descriptionName" class="input__summary">{{ summary }}</span>
</div>
