<div
  class="select input"
  [ngClass]="{
    'input--highlight': highlightError | async,
    'select--has-value': control.value,
    'select--menu-open': editing,
    'select--display': !editing,
    'select--disabled': !enabled,
    'select--invalid': !control.valid,
    'input--invalid': !control.valid,
    'select--touched': control.touched,
    'input--touched': control.touched,
  }"
  (clickOutside)="close()"
  [openState]="editing"
>
  <div class="select__value-container">
    <button type="button" class="select__button" (click)="open()" (focusin)="open()">Select {{ singular }}</button>
    @if (control.value) {
      <div class="select__value">
        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: control.value }"> </ng-container>
      </div>
    }
    <label [for]="selectSearchInput" class="select__label">{{ label }}</label>
    <span class="select__bar input__bar"></span>
    <ftr-icon class="select__arrow select__arrow--right" icon="KeyboardArrowRight"></ftr-icon>
    <ftr-icon class="select__arrow select__arrow--down" icon="ArrowDropDown"></ftr-icon>
    <span class="select__instructions">{{ instructions }}</span>
    <span
      class="input__validation select__validation"
      ftrValidationErrorHint
      [label]="label"
      [ngFormControl]="control"
      [controlTouched]="control.touched"
    ></span>
  </div>
  <div class="select__open-menu-container">
    <div class="select__input-container">
      <button ftrButton type="button" displayType="icon" class="select__close" (click)="close()">
        <ftr-icon icon="KeyboardArrowLeft" align="Middle"></ftr-icon>
      </button>
      <ftr-icon class="select__search-icon" icon="Search" color="GreyLight"></ftr-icon>
      <input
        #selectSearchInput
        name="selectSearch"
        class="select__search-input"
        [ngClass]="{ 'select__search-input--has-value': control.value }"
        type="text"
        [formControl]="searchTerm"
        [placeholder]="'Search ' + plural"
        [ftrFocus]="focusEmitter"
        (keydown.enter)="onSearchSelect($event)"
        (keydown.tab)="close()"
      />
    </div>
    <div class="select__items">
      @if ((filteredItems | async)?.length === 0) {
        <div class="select__items-empty">
          @if (searchTerm.value) {
            No {{ plural }} have been found with the search term "<span class="select__empty-term">{{
              searchTerm.value
            }}</span
            >"
          } @else {
            No {{ plural }} have been found
          }
        </div>
      }
      <ng-container *ngTemplateOutlet="itemsTemplate; context: { $implicit: filteredItems, selected: control.value }">
      </ng-container>
    </div>
  </div>
</div>
