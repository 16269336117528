<ftr-form-input
  name="password"
  [type]="type"
  [label]="label"
  [autofocus]="autofocus"
  [ngFormControl]="ngFormControl"
  [required]="true"
  [highlightError]="highlightError"
  [notrack]="true"
  [hideValidationErrors]="hideValidationErrors"
  [autocomplete]="autocomplete"
>
</ftr-form-input>
<button
  type="button"
  (click)="toggleVisibility()"
  class="password__visibility"
  [ngClass]="{
    'password__visibility--hidden': type === 'password',
    'password__visibility--visible': type === 'text',
  }"
>
  <ftr-icon class="password__make-visible" icon="Visibility" align="Bottom" label="Make password visible"></ftr-icon>
  <ftr-icon
    class="password__make-invisible"
    icon="VisibilityOff"
    align="Bottom"
    label="Make password invisible"
  ></ftr-icon>
</button>
