<div class="top-bar-display" [class.top-bar-display--search-opened]="isSearchingSignal()">
  @if (!isSearchingSignal()) {
    <div class="top-bar-display__row">
      <div class="top-bar-display__column">
        <ng-container *ngTemplateOutlet="toggleSideBarButton"></ng-container>
        <ftr-breadcrumbs class="top-bar-display__breadcrumbs"></ftr-breadcrumbs>
      </div>
      <div class="top-bar-display__column">
        @if (currentCourtSystemId()) {
          <ftr-top-bar-search
            [courtSystemId]="currentCourtSystemId()!"
            [isSearching]="isSearchingSignal()"
          ></ftr-top-bar-search>
        }
        <ftr-account-dropdown [currentCourtSystemId]="currentCourtSystemId()"></ftr-account-dropdown>
      </div>
    </div>
  } @else {
    <div class="top-bar-display__row">
      <div class="top-bar-display__column">
        <ng-container *ngTemplateOutlet="toggleSideBarButton"></ng-container>
      </div>
      @if (currentCourtSystemId()) {
        <ftr-top-bar-search
          [courtSystemId]="currentCourtSystemId()!"
          [isSearching]="isSearchingSignal()"
        ></ftr-top-bar-search>
      }
    </div>
  }
  <!-- To prevent ftr-breadcrumbs from showing without content -->
  @if (showingBreadcrumbs) {
    <div class="top-bar-display__breadcrumbs--mobile">
      <ftr-breadcrumbs></ftr-breadcrumbs>
    </div>
  }
</div>

<ng-template #toggleSideBarButton>
  <button
    class="top-bar-display__menu"
    (click)="toggleSideBar()"
    type="button"
    ftrButton
    title="Show Navigation Menu"
    aria-label="Show Navigation Menu"
    [autoWidth]="true"
    displayType="icon"
    [color]="buttonColor"
  >
    <ftr-icon icon="Menu" align="Middle"></ftr-icon>
  </button>
</ng-template>
