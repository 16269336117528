// eslint-disable-next-line max-classes-per-file
import { Uuid } from '@ftr/contracts/type/shared'

export class HearingDeletedEvent {
  static readonly type = '[Hearings] Hearing Deleted'

  constructor(
    readonly hearingId: Uuid,
    readonly caseDeleted: boolean,
  ) {}
}

export class HearingDeletionFailedEvent {
  static readonly type = '[Hearings] Hearing Deletion Failed'

  constructor(readonly hearingId: Uuid) {}
}
