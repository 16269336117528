export enum CourtSystemAdminPaths {
  AuditLog = 'audit-log',
  AudioCostWaiver = 'audio-cost-waiver',
  AudioDisclaimer = 'audio-disclaimer',
  AudioDocuments = 'audio-documents',
  AudioPublicOrdering = 'audio-public-ordering',
  AudioReceipts = 'audio-receipts',
  AudioVideoPlayback = 'audio-video-playback',
  BillingGroups = 'billing-groups',
  Courthouses = 'courthouses',
  Departments = 'departments',
  Holidays = 'holidays',
  JudgesAndAppearances = 'judges-and-appearances',
  JudgeSettings = 'judge-settings',
  ManageProceedings = 'real-time-hearings',
  MfaRequirement = 'mfa',
  Personalization = 'personalization',
  PortionsToTranscribe = 'portions-to-transcribe',
  Pricing = 'pricing',
  Proceedings = 'proceedings',
  RealTimeRecorders = 'recorders',
  RecorderSettings = 'recorder-settings',
  RealTimePublicOrdering = 'real-time-public-ordering',
  RealTimeVideoPlayback = 'real-time-video-playback',
  RemovalRequests = 'removal-requests',
  Repositories = 'repositories',
  RestApiKeyAccess = 'rest-api-key-access',
  RestrictedPermissions = 'restricted-permissions',
  ScheduleProceeding = 'schedule',
  SharingRecordingsVideoPlayback = 'sharing-recordings-video-playback',
  SttRateLimits = 'stt-rate-limits',
  TimeZone = 'timezone',
  TranscriptBusinessHours = 'transcript-business-hours',
  TranscriptCaseType = 'transcript-case-type',
  TranscriptCostWaiver = 'transcript-cost-waiver',
  TranscriptDocuments = 'transcript-documents',
  TranscriptLanguages = 'transcript-languages',
  TranscriptNotes = 'transcript-notes',
  TranscriptPaperCopies = 'transcript-paper-copies',
  TranscriptProvider = 'transcript-provider',
  TranscriptPublicOrdering = 'transcript-public-ordering',
  TranscriptReceipts = 'transcript-receipts',
  TranscriptVideoPlayback = 'transcript-video-playback',
  Turnarounds = 'turnarounds',
  UseConditions = 'use-conditions',
  UserGroups = 'user-groups',
  Users = 'users',
  Vocabulary = 'vocabulary',
  RecordingLicense = 'recording-license',
  Licensing = 'licensing',
}
