<div class="form-input-pill-list">
  <label
    class="form-input-pill-list__label"
    [class.form-input-pill-list__label--optional]="!required"
    [attr.for]="id + 'Input'"
  >
    {{ label }}
  </label>

  <div class="form-input-pill-list__wrapper">
    @for (pill of listControlValue; track pill; let i = $index) {
      <ftr-pill
        class="form-input-pill-list__pill"
        [value]="pill"
        [tabIndex]="i === 0 ? 1 : -1"
        [iconAfter]="iconAfterTemplate"
        (iconAfterClick)="deletePill($event)"
        ftrNavigatePill
        (onNavigatePrev)="navigatePrev($event)"
        (onNavigateNext)="navigateNext($event)"
        (onDelete)="deletePillByNavigation($event)"
        [previousPill]="listControlValue[i - 1]"
        [nextPill]="listControlValue[i + 1]"
        [currentPill]="pill"
      >
        {{ pill }}
      </ftr-pill>
    }
    <input
      #input
      [id]="id + 'Input'"
      [name]="name"
      [attr.aria-describedby]="id + 'Description ' + id + 'Error'"
      type="text"
      spellcheck="false"
      [formControl]="inputControl"
      class="input__control form-input-pill-list__input"
      [class.form-input-pill-list__input--invalid]="inputControl.touched && !inputControl.valid"
      [attr.aria-invalid]="inputControl.touched && !inputControl.valid ? 'true' : 'false'"
      [ftrPillInput]="inputControl"
      [pillCreationTriggers]="pillCreationTriggers"
      (blur)="createNewPill()"
      (onCreateNewPill)="createNewPill()"
      (onPaste)="createNewPill()"
      (onNavigatePrev)="navigatePrev($event)"
    />
    <label [for]="input" class="form-input-pill-list__hidden-label input__label" aria-hidden="true">{{ label }}</label>
    <div class="input__bar"></div>
  </div>
  <span
    class="input__validation"
    [id]="id + 'Error'"
    ftrValidationErrorHint
    [ngFormControl]="inputControl"
    [label]="validationLabel"
    [controlTouched]="inputControl.touched"
  ></span>
  @if (displayHintTemplate) {
    <p class="input__helper" [ngClass]="{ 'screenreader-only': !displayHint }" [id]="id + 'Description'">
      <ng-container *ngTemplateOutlet="displayHintTemplate"></ng-container>
    </p>
  }
</div>

<ng-template #iconAfterTemplate>
  <ftr-icon icon="Cancel" label="Delete email" size="Regular" align="Middle"></ftr-icon>
</ng-template>
