import { AsyncPipe, NgClass } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { generateUuid } from '@ftr/contracts/type/shared/Uuid'
import {
  DateFormat,
  DefaultTimeStringParseMethod,
  IconComponent,
  TimeStringParseMethod,
  TimeStringParser,
  jodaFormatPatternWithLocale,
} from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import { Observable, distinctUntilChanged, of } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'

export const INVALID_TIME_MESSAGE = 'The time provided is invalid.'

@Component({
  standalone: true,
  selector: 'ftr-time-input',
  imports: [IconComponent, NgClass, AsyncPipe, ReactiveFormsModule, ValidationErrorHintDirective],
  templateUrl: './time-input.component.html',
})
/**
 * An input for entering times. It will automatically try to interpret non-default time formats
 * like "8pm" and convert them to "8:00 PM".
 */
export class TimeInputComponent implements OnInit {
  readonly validators = Validators

  @Input() label: string = 'Time'

  @Input() id: string = generateUuid()

  @Input() name: string = 'time'

  /**
   * Whether submission of the form this component is part of has been attempted. When submission is attempted, the
   * underlying validation control (i.e.: ValidationHint) may choose to display validation states for fields which have
   * not yet been touched.
   */
  @Input() submitAttempted = false

  /**
   * The form control driving the value in this component.
   */
  @Input() control: FormControl<LocalTime | null>

  /**
   * Whether to highlight field errors, as per ftr-form.
   */
  @Input() highlightError: Observable<boolean> = of(false)

  /**
   * The placeholder for the input.
   */
  @Input() placeholder: string = 'HH:MM AM/PM'

  /**
   * The format to use
   */
  @Input() timeFormat: DateFormat = DateFormat.Time

  @Input() invalidTimeMessage: string = INVALID_TIME_MESSAGE

  @Input() timeStringParseMethods: TimeStringParseMethod[] = [DefaultTimeStringParseMethod]

  @Output() inputChanged = new EventEmitter<void>()

  /**
   * The value of the input needs to change to be re-rendered on the page.
   * When we have a valid value, then enter an invalid one and back to the same value,
   * it doesn't register as a different value, therefore it doesn't get parsed properly.
   * E.g. '10:00:00 AM' -> 'gg' -> '10am', all we see is just '10am'.
   * That's why we need this intermediary string to store the actual input value.
   */
  internalValue: string

  ngOnInit(): void {
    this.internalValue = this.formatValue(this.control.value)
    this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      this.internalValue = this.formatValue(value)
    })
  }

  inputChange(value: string): void {
    this.control.markAsTouched()
    if (value.trim() === '') {
      this.internalValue = ''
      this.control.setValue(null)
      this.inputChanged.emit()
      return
    }

    try {
      const updatedTime = TimeStringParser.parse(value.trim(), this.timeStringParseMethods)
      this.control.setValue(updatedTime)
      this.internalValue = this.formatValue(updatedTime)
    } catch {
      // Can't see the error message without setTimeout
      setTimeout(() => {
        this.control.setErrors({
          invalidFormat: this.invalidTimeMessage,
        })
        this.internalValue = value.trim()
      })
    }

    this.inputChanged.emit()
  }

  private formatValue(value: LocalTime | null): string {
    if (!value) {
      return ''
    }

    return value.format(jodaFormatPatternWithLocale(this.timeFormat)) ?? ''
  }
}
