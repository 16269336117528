import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import {
  ACCESS_TOKEN_AMR_CLAIM_HASH_KEY,
  ACCESS_TOKEN_AMR_CLAIM_KEY,
  AUTH_CONFIGURATION,
  AuthConfiguration,
  CODE_VERIFIER_PARAM,
  CODE_VERIFIER_PARAM_LENGTH,
  DiscoveryResponse,
  IDENTITY_PROVIDER,
  ID_TOKEN_EXPIRES_AT_KEY,
  ID_TOKEN_KEY,
  IdentityProviderConfiguration,
  IdentityProviderConfigurationCache,
  Oauth2TokenResponse,
  REFRESH_TOKEN_KEY,
  STATE_PARAM,
  STATE_PARAM_LENGTH,
  TOKEN_EXPIRY_THRESHOLD_SECONDS,
  TokenStatus,
  base64URLEncode,
  toSha256,
} from '@ftr/api-shared'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { LocalStorageService, SimpleWindowRefService } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { LoggingService } from '@ftr/ui-observability'
import { lastValueFrom } from 'rxjs'

interface IdTokenDetails {
  value: string
  isNew?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  http: HttpClient
  private readonly helper = new JwtHelperService()
  private readonly idpConfigurationCache: IdentityProviderConfigurationCache = {}
  private readonly redirectUrl: string

  constructor(
    handler: HttpBackend,
    @Inject(AUTH_CONFIGURATION) readonly configurationService: AuthConfiguration,
    private readonly windowRefService: SimpleWindowRefService,
    private readonly localStorageService: LocalStorageService,
    private readonly loggingService: LoggingService,
  ) {
    // this bypasses the JWT HttpInterceptor and avoids the interceptor->auth->http->interceptor circular dependency
    this.http = new HttpClient(handler)
    this.redirectUrl = `${this.windowRefService.getHostName()}/${AppPaths.ExternalLogin}`
  }

  /**
   * Generates a code challenge and stores the verifier in local storage
   */
  generateCodeChallenge(): string {
    const codeVerifier = base64URLEncode(randomString(CODE_VERIFIER_PARAM_LENGTH))
    this.localStorageService.setGlobal(CODE_VERIFIER_PARAM, codeVerifier)
    return base64URLEncode(toSha256(codeVerifier))
  }

  /**
   * Generates and stores a state nonce
   */
  generateState(): string {
    const state = base64URLEncode(randomString(STATE_PARAM_LENGTH))
    this.localStorageService.setGlobal(STATE_PARAM, state)
    return state
  }

  /**
   * Redirects the user to the login page of an external identity provider.
   * https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
   * @param identityProvider the identity provider (token issuer, e.g. Google or Microsoft)
   * @param codeChallenge an optional code challenge, currently used when a native app is requesting auth
   * @param state and optional state nonce, currently used when a native app is requesting auth
   */
  async loginWithRedirect(
    identityProvider: IdentityProviderName,
    codeChallenge?: string | undefined,
    state?: string | undefined,
  ): Promise<void> {
    const config = await this.getProviderConfiguration(identityProvider)

    let queryParams = new HttpParams()
      .append('client_id', config.clientId)
      .append('response_type', 'code')
      .append('redirect_uri', this.redirectUrl)
      .append('prompt', 'select_account')
      .append('state', state ?? this.generateState())
      .append('code_challenge', codeChallenge ?? this.generateCodeChallenge())
      .append('code_challenge_method', 'S256')

    if (this.isUsingCognitoIntegration(identityProvider)) {
      // pass the identity_provider param to bypass the provider selection
      queryParams = queryParams.append('identity_provider', identityProvider)
    } else {
      queryParams = queryParams.append('scope', 'openid email profile')
    }

    this.windowRefService.location().replace(`${config.authorizeEndpoint}?${queryParams.toString()}`)
  }

  /**
   * Calls the token endpoint to exchange the authorization code for the tokens.
   * https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
   * @param identityProvider the identity provider (token issuer, e.g. Google or Microsoft)
   * @param authCode the authorization code to be exchanged
   * @param state a random string added to the authorization request which is included in server response
   * to be validated. This is to prevent CSRF attacks.
   */
  async exchangeCodeForToken(identityProvider: IdentityProviderName, authCode: string, state?: string): Promise<void> {
    if (state && !this.isValidState(state)) {
      throw new Error('Invalid state, response may have been tampered with.')
    }

    const codeVerifier = this.localStorageService.getGlobal<string>(CODE_VERIFIER_PARAM)
    if (!codeVerifier) {
      throw new Error('Missing code verifier.')
    }

    const config = await this.getProviderConfiguration(identityProvider)

    const body = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('client_id', config.clientId)
      .append('code', authCode)
      .append('redirect_uri', this.redirectUrl)
      .append('code_verifier', codeVerifier)

    const oauthTokens = await lastValueFrom(
      this.http.post<Oauth2TokenResponse>(config.tokenEndpoint, body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }),
    )

    this.storeTokens(oauthTokens)
  }

  async getIdToken(): Promise<string> {
    const idTokenDetails = await this.getIdTokenDetails()
    return idTokenDetails.value
  }

  async getTokenStatus(): Promise<TokenStatus> {
    const { value, isNew } = await this.getIdTokenDetails()
    return { isTokenValid: !!value, isTokenNew: isNew }
  }

  logOut(): void {
    this.localStorageService.removeGlobal(ID_TOKEN_KEY)
    this.localStorageService.removeGlobal(ID_TOKEN_EXPIRES_AT_KEY)
    this.localStorageService.removeGlobal(REFRESH_TOKEN_KEY)
    this.localStorageService.removeGlobal(STATE_PARAM)
    this.localStorageService.removeGlobal(CODE_VERIFIER_PARAM)
    this.localStorageService.removeGlobal(ACCESS_TOKEN_AMR_CLAIM_KEY)
  }

  /**
   * Returns true if the given identity provider is integrated via Cognito.
   */
  isUsingCognitoIntegration(identityProvider: IdentityProviderName): boolean {
    return [IdentityProviderName.Facebook, IdentityProviderName.Google].includes(identityProvider)
  }

  getMultifactorAuthenticationEnabled(): boolean | undefined {
    const amrClaim = this.localStorageService.getGlobal(ACCESS_TOKEN_AMR_CLAIM_KEY) as string[]
    const hashedAmrClaim = this.localStorageService.getGlobal(ACCESS_TOKEN_AMR_CLAIM_HASH_KEY)
    if (base64URLEncode(toSha256(amrClaim?.join() || '')) !== hashedAmrClaim) {
      return false
    }
    return amrClaim ? amrClaim.some(v => v === 'mfa') : undefined
  }

  private async getProviderConfiguration(
    identityProviderName: IdentityProviderName,
  ): Promise<IdentityProviderConfiguration> {
    if (!this.idpConfigurationCache[identityProviderName]) {
      this.idpConfigurationCache[identityProviderName] = await this.configureForProvider(identityProviderName)
    }
    return this.idpConfigurationCache[identityProviderName]
  }

  private async configureForProvider(
    identityProviderName: IdentityProviderName,
  ): Promise<IdentityProviderConfiguration> {
    const discoveryEndpoint = this.getDiscoveryEndpoint(identityProviderName)
    if (!discoveryEndpoint) {
      throw new Error(`Error Configuring ${identityProviderName} Identity Provider. No matching endpoint found.`)
    }
    try {
      const endpoints = await lastValueFrom(
        this.http.get<DiscoveryResponse>(`${discoveryEndpoint}/.well-known/openid-configuration`),
      )
      return {
        clientId: this.getAuthAppClientId(identityProviderName),
        authorizeEndpoint: endpoints.authorization_endpoint,
        tokenEndpoint: endpoints.token_endpoint,
      }
    } catch (err) {
      this.loggingService.error({ err })
      throw new Error(`Error Configuring ${identityProviderName} Identity Provider.`)
    }
  }

  private getDiscoveryEndpoint(identityProvider: IdentityProviderName): string {
    if (this.isUsingCognitoIntegration(identityProvider)) {
      return this.configurationService.cognitoAuthUrl
    }

    if (identityProvider === IdentityProviderName.Microsoft) {
      return this.configurationService.microsoftAuthUrl
    }

    if (identityProvider === IdentityProviderName.Mock) {
      return this.configurationService.mockAuthUrl
    }

    throw new Error('Identity provider not supported.')
  }

  private getAuthAppClientId(identityProvider: IdentityProviderName): string {
    if (this.isUsingCognitoIntegration(identityProvider)) {
      return this.configurationService.userPool.getClientId()
    }

    if (identityProvider === IdentityProviderName.Microsoft) {
      return this.configurationService.microsoftAuthAppId
    }

    if (identityProvider === IdentityProviderName.Mock) {
      return IdentityProviderName.Mock
    }

    throw new Error('Identity provider not supported.')
  }

  private isValidState(state: string): boolean {
    const localState = this.localStorageService.getGlobal<string>(STATE_PARAM)

    if (!localState) {
      return false
    }

    return localState === state || state.indexOf(localState) >= 0
  }

  private storeTokens(tokens: Oauth2TokenResponse): void {
    const idToken = this.helper.decodeToken(tokens.id_token)
    this.localStorageService.setGlobal(ID_TOKEN_KEY, tokens.id_token)
    this.localStorageService.setGlobal(REFRESH_TOKEN_KEY, tokens.refresh_token)
    this.localStorageService.setGlobal(ID_TOKEN_EXPIRES_AT_KEY, idToken.exp)

    // Attempt to obtain MFA status from accessToken
    const amrClaim = this.getAmrClaimFromAccessToken(tokens.access_token)
    this.localStorageService.setGlobal(ACCESS_TOKEN_AMR_CLAIM_KEY, amrClaim)
  }

  /**
   * Calls the token endpoint to refresh the tokens.
   * https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
   */
  private async refreshTokens(): Promise<Oauth2TokenResponse> {
    const identityProvider = this.localStorageService.getGlobal<IdentityProviderName>(IDENTITY_PROVIDER)

    if (!identityProvider) {
      throw new Error('Unable to refresh tokens due to missing identity provider')
    }

    const refreshToken = this.localStorageService.getGlobal<string>(REFRESH_TOKEN_KEY)

    if (!refreshToken) {
      throw new Error('Unable to refresh tokens due to missing refresh token')
    }

    const config = await this.getProviderConfiguration(identityProvider)

    const body = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('client_id', config.clientId)
      .append('refresh_token', refreshToken)

    const oauthTokens = await lastValueFrom(
      this.http.post<Oauth2TokenResponse>(config.tokenEndpoint, body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }),
    )

    // Some auth servers don't return refresh_token when the grant_type is refresh_token
    oauthTokens.refresh_token = oauthTokens.refresh_token || refreshToken

    this.storeTokens(oauthTokens)
    return oauthTokens
  }

  private async getIdTokenDetails(): Promise<IdTokenDetails> {
    const idToken = this.localStorageService.getGlobal<string>(ID_TOKEN_KEY)
    let exp = this.localStorageService.getGlobal<number>(ID_TOKEN_EXPIRES_AT_KEY)

    if (idToken && exp) {
      if (exp && String(exp).length === 13) {
        // convert old values back to seconds
        exp = msToSeconds(exp)
      }

      const now = msToSeconds(Date.now())

      if (now < exp - TOKEN_EXPIRY_THRESHOLD_SECONDS) {
        // token is valid and not about to expire
        return { value: idToken, isNew: false }
      }

      try {
        // refresh tokens
        const tokens = await this.refreshTokens()
        return { value: tokens.id_token, isNew: true }
      } catch {
        // remove expired token
        this.logOut()
      }
    }

    // token is missing or has been removed
    return { value: '' }
  }

  private getAmrClaimFromAccessToken(accessToken: string): string[] | undefined {
    try {
      const decoded = this.helper.decodeToken(accessToken)
      const hashedAmrClaim = base64URLEncode(toSha256(decoded.amr?.join() || ''))
      this.localStorageService.setGlobal(ACCESS_TOKEN_AMR_CLAIM_HASH_KEY, hashedAmrClaim)
      return decoded.amr
    } catch {
      return undefined
    }
  }
}

export function msToSeconds(ms: number): number {
  return Math.floor(ms / 1_000)
}

function randomString(length: number): string {
  let result = ''
  const unreservedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  while (0 < length--) {
    result += unreservedChars[(Math.random() * unreservedChars.length) | 0]
  }
  return result
}
